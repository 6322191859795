
import { defineComponent, PropType } from "vue-demi";
import { getCorrectFileSize } from '@/utils/files';
import { CancelIcon } from "@/plugins/icons";
import { File } from "@/interfaces/file/file.dto";
import { useAPI } from "@/use";

export default defineComponent({
  emits: ['uploadFiles', 'deleteFile'],
  name: "ModalFiles",
  props: {
    input: {
      type: Object,
      required: true,
    },
    files: {
      type: Object as PropType<FileList>
    }
  },
  methods: {
    getCorrectFileSize,
    async deleteImage(file: File.Dto | File, index: number) {
      if ((file as File.Dto)._id) {
        await useAPI().file.deleteProductImageMethod((file as File.Dto)._id);
        this.$emit('deleteFile', index);
      } else {
        this.$emit('deleteFile', index);
      }
    },
    previewFileSrc(file: File) {
      return URL.createObjectURL(file);
    },
  },
  components: {
    CancelIcon
  }
});
